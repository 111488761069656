import React from "react";
import { isEmpty } from 'lodash';
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { getOgImage } from "../../utils/functions";
import CategoryContent from "../../components/category/CategoryContent";

const ProductCategories = (props) => {
  const {
    pageContext: {
      name, seo, uri, id, children, products, description, acfProductCategory, options
    }
  } = props;
  const { code: locale } = props.pageContext.wpmlTranslated.current[0];
  const { node: translated } = props.pageContext.wpmlTranslated
  // const options = {
  //   headingPrice: '',
  //   buttonPrice: '',
  //   textPrice: '',
  //   headingSort: '',
  //   headingCategories: '',
  //   typeSort: [
  //     {
  //       heading: '',
  //       type: ''
  //     }
  //   ],
  // }
  const lang = locale.substring(0, 2);
  return (
    <Layout lang={lang} page="productCategories" translated={translated}>
      {
        !isEmpty(props.pageContext) ? (
          <>
            <SEO
              title={name}
translated={translated}
              seoData={seo}
              uri={uri}
              lang={lang}
              page={"productCategories"}
              // header={ siteTitle: 'Gatsby WooCommerce Theme' }}
              openGraphImage={getOgImage(seo)}
            />
            <CategoryContent
              id={id}
              lang={lang}
              options={options.template.productCategory}
              productBoxButton={acfProductCategory.buttonTextProduct}
              breadcrumbs={acfProductCategory.breadcrumbs}
              children={children}
              products={products}
              title={name}
translated={translated}
              description={description}
            />
          </>
        ) : (
          <div>Something went wrong</div>
        )
      }
    </Layout>
  )
};
export default ProductCategories;